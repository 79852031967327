export default [
  {
    title: '值名称',
    'min-width': 300,
    property: 'name',
    showIcon: 'input',
  },
  {
    title: '创建人',
    width: 200,
    property: 'create_user',
  },
  {
    title: '更新时间',
    width: 300,
    property: 'update_time',
    sort: true,
    format: true,
  },
  {
    title: '覆盖数据',
    width: 200,
    property: 'data_count',
  },
];
