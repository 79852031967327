export default [
  {
    title: '性别',
    property: 'gender',
    search: 'select',
  },
  {
    title: '所属机构名称',
    property: 'hco_name',
    search: 'input',
  },
  {
    title: 'HCP类型',
    property: 'hcp_type',
    search: 'select',
  },
  {
    title: '挂牌科室',
    property: 'listed_dept_name',
    search: 'input',
  },
  {
    title: '标准科室',
    property: 'standard_dept_name',
    search: 'cascader',
  },
  {
    title: '专业职称',
    property: 'professional_title',
    search: 'select',
  },
  {
    title: '学术头衔',
    property: 'academic_title',
    search: 'select',
  },
  {
    title: '行政职务',
    property: 'administrative_title',
    search: 'select',
  },
  {
    title: '执业地点',
    property: 'nhc_location',
    search: 'input',
  },
  {
    title: '执业范围',
    property: 'nhc_scope',
    search: 'input',
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    search: 'select',
  },
  {
    title: '数据来源',
    property: 'source',
    search: 'select',
  },
];
