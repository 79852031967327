export default [
  {
    title: '任务类型',
    width: 400,
    property: 'exec_type',
  },
  {
    title: '运行状态',
    width: 400,
    property: 'status',
    // showIcon: 'select',
  },
  {
    title: '创建人',
    width: 400,
    property: 'create_user',
  },
  {
    title: '开始时间',
    width: 400,
    property: 'start_time',
    sort: true,
    format: true,
  },
  {
    title: '完成时间',
    width: 400,
    property: 'end_time',
    sort: true,
    format: true,
  },
  {
    title: '耗时',
    width: 400,
    property: 'time_dur',
  },
  {
    title: '生成标签数量',
    width: 400,
    property: 'data_count',
  },
];
