export default [
  {
    title: '机构名称',
    property: 'name',
    search: 'input',
  },
  {
    title: '类型细分',
    property: 'type_name',
    search: 'cascader',
  },
  {
    title: '医院级别',
    property: 'hospital_level',
    search: 'select',
  },
  {
    title: '行政区划',
    property: 'admin_division',
    search: 'cascader',
  },
  {
    title: '是否医保',
    property: 'is_medical_insurance',
    search: 'select',
  },
  {
    title: '所有制性质',
    property: 'ownership',
    search: 'select',
  },
  {
    title: '医院营利性质',
    property: 'hospital_profit_type',
    search: 'select',
  },
  {
    title: '上级机构名称',
    property: 'parent_hco',
    search: 'input',
  },
  {
    title: '卫健委名称',
    property: 'nhc_name',
    search: 'input',
  },
  {
    title: '诊疗科目',
    property: 'outpatient_subjects',
    search: 'input',
  },
  {
    title: '曾用名',
    property: 'former_name',
    search: 'input',
  },
  {
    title: '别名',
    property: 'alias_name',
    search: 'input',
  },
  {
    title: '经营方式',
    property: 'operation_mode',
    search: 'input',
  },
  {
    title: '经营范围',
    property: 'business_scope',
    search: 'input',
  },
  {
    title: '所属行业',
    property: 'business_type',
    search: 'input',
  },
  {
    title: '经营状态',
    property: 'business_status',
    search: 'select',
  },
  {
    title: '公司类型',
    property: 'company_type',
    search: 'input',
  },
  {
    title: '成立日期',
    property: 'establishment_date',
    search: 'date',
    format: true,
  },
  {
    title: '特色科室',
    search: 'input',
    property: 'hospital_distinctive_department',
  },
  {
    title: '病床数量',
    search: 'num',
    property: 'hospital_sickbed_count',
  },
  {
    title: '门急诊数量',
    search: 'num',
    property: 'hospital_outpatient_count',
  },
  {
    title: '科室数量',
    search: 'num',
    property: 'hospital_department_count',
  },
  {
    title: '执业医师数量',
    search: 'num',
    property: 'hospital_doctor_count',
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    search: 'select',
  },
  {
    title: '数据来源',
    property: 'source',
    search: 'select',
  },
];
