const hcoCustom = [
  {
    key: 'name',
    label: '机构名称',
  },
  {
    key: 'hco_code',
    label: '机构编码',
  },
  {
    key: 'hco_type',
    label: '二级分类',
  },
  {
    key: 'type_name',
    label: '类型细分',
  },
  {
    key: 'parent_hco',
    label: '上级机构名称',
  },
  {
    key: 'hospital_level',
    label: '医院级别',
  },
  {
    key: 'ownership',
    label: '所有制性质',
  },
  {
    key: 'is_medical_insurance',
    label: '是否医保',
  },
  {
    key: 'hospital_profit_type',
    label: '医院营利性质',
  },
  {
    key: 'nhc_name',
    label: '卫健委名称',
  },
  {
    key: 'issuing_authority',
    label: '审批机关',
  },
  {
    key: 'outpatient_subjects',
    label: '诊疗科目',
  },
  {
    key: 'legal_person',
    label: '法人',
  },
  {
    key: 'charge_persons',
    label: '企业负责人',
  },
  {
    key: 'former_name',
    label: '曾用名',
  },
  {
    key: 'en_name',
    label: '英文名',
  },
  {
    key: 'alias_name',
    label: '别名',
  },
  {
    key: 'admin_division',
    label: '行政区划',
  },
  {
    key: 'address',
    label: '地址',
  },
  {
    key: 'postcode',
    label: '邮编',
  },
  {
    key: 'website',
    label: '主页',
  },
  {
    key: 'phone1',
    label: '电话1',
  },
  {
    key: 'email1',
    label: '邮箱1',
  },
  {
    key: 'wx_gzh1',
    label: '微信公众号1',
  },
  {
    key: 'registration_code',
    label: '注册号',
  },
  {
    key: 'registered_address',
    label: '注册地址',
  },
  {
    key: 'registered_capital',
    label: '注册资金',
  },
  {
    key: 'organization_code',
    label: '组织机构代码',
  },
  {
    key: 'operation_mode',
    label: '经营方式',
  },
  {
    key: 'business_scope',
    label: '经营范围',
  },
  {
    key: 'business_type',
    label: '所属行业',
  },
  {
    key: 'business_status',
    label: '经营状态',
  },
  {
    key: 'company_type',
    label: '公司类型',
  },
  {
    key: 'unified_social_credit_code',
    label: '统一社会信用代码',
  },
  {
    key: 'taxpayer_identification_code',
    label: '纳税人识别号',
  },
  {
    key: 'establishment_date',
    label: '成立日期',
  },
  {
    key: 'hospital_distinctive_department',
    label: '特色科室',
  },
  {
    key: 'hospital_sickbed_count',
    label: '病床数量',
  },
  {
    key: 'hospital_outpatient_count',
    label: '门急诊数量',
  },
  {
    key: 'hospital_department_count',
    label: '科室数量',
  },
  {
    key: 'hospital_doctor_count',
    label: '执业医师数量',
  },
  {
    key: 'wmqe_number',
    label: '未名企鹅编码',
  },
  {
    key: 'in_doubt',
    label: '是否存疑',
  },
  {
    key: 'doubt_reason',
    label: '存疑原因',
  },
  {
    key: 'source',
    label: '数据来源',
  },
  {
    key: 'comment',
    label: '备注评论',
  },
  {
    key: 'qa_persons',
    label: '质量负责人',
  },
  {
    key: 'record_version',
    label: '版本',
  },
  {
    key: 'create_user',
    label: '创建人',
  },
  {
    key: 'create_time',
    label: '创建时间',
  },
  {
    key: 'update_user',
    label: '更新人',
  },
  {
    key: 'update_time',
    label: '更新时间',
  },
];

const hcpCustom = [
  {
    key: 'name',
    label: '姓名',
  },
  {
    key: 'hcp_code',
    label: '人员编码',
  },
  {
    key: 'pinyin',
    label: '姓名拼音',
  },
  {
    key: 'gender',
    label: '性别',
  },
  {
    key: 'hco_name',
    label: '所属机构名称',
  },
  {
    key: 'hcp_type',
    label: 'HCP类型',
  },
  {
    key: 'hcp_status',
    label: 'HCP状态',
  },
  {
    key: 'listed_dept_name',
    label: '挂牌科室',
  },
  {
    key: 'standard_dept_name',
    label: '标准科室',
  },
  {
    key: 'academic_title',
    label: '学术头衔',
  },
  {
    key: 'professional_title',
    label: '专业职称',
  },
  {
    key: 'administrative_title',
    label: '行政职务',
  },
  {
    key: 'nhc_license_number',
    label: '执业许可证编码',
  },
  {
    key: 'nhc_issuing_authority',
    label: '颁发机构',
  },
  {
    key: 'nhc_professional_level',
    label: '医师级别',
  },
  {
    key: 'nhc_professional_type',
    label: '执业类别',
  },
  {
    key: 'nhc_location',
    label: '执业地点',
  },
  {
    key: 'nhc_scope',
    label: '执业范围',
  },
  {
    key: 'nhc_multi_sites',
    label: '多点执业数量',
  },
  {
    key: 'nhc_issue_date',
    label: '证书颁发日期',
  },
  {
    key: 'wmqe_number',
    label: '未名企鹅编码',
  },
  {
    key: 'in_doubt',
    label: '是否存疑',
  },
  {
    key: 'doubt_reason',
    label: '存疑原因',
  },
  {
    key: 'source',
    label: '数据来源',
  },
  {
    key: 'comment',
    label: '备注评论',
  },
  {
    key: 'qa_persons',
    label: '质量负责人',
  },
  {
    key: 'record_version',
    label: '版本',
  },
  {
    key: 'create_user',
    label: '创建人',
  },
  {
    key: 'create_time',
    label: '创建时间',
  },
  {
    key: 'update_user',
    label: '更新人',
  },
  {
    key: 'update_time',
    label: '更新时间',
  },
];

export { hcoCustom, hcpCustom };
