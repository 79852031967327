<template>
  <div class="tag_detail_warp">
    <!-- 标题 -->
    <div class="tag_detail_warp_top">
      <div class="tag_detail_title">
        {{ tagDetail.type === 'hco' ? 'HCO标签详情' : 'HCP标签详情' }}
      </div>
      <div class="tag_detail_top_btn" v-if="tagDetail.tag_productMode !== '算法生成'">
        <el-button
          :disabled="listedDisabled"
          plain
          v-if="tagDetail.on_self !== '已上架'"
          @click="handleListed(1)"
        >
          上架
        </el-button>
        <el-button plain class="manualOpt" v-if="tagDetail.on_self === '已上架'" @click="handleRun">
          手动运行
        </el-button>
        <el-button plain v-if="tagDetail.on_self === '已上架'" @click="hanldeShelfOff">
          下架
        </el-button>
        <el-button plain v-if="tagDetail.on_self !== '已上架'" @click="handleDel">删除</el-button>
        <el-tooltip
          class="item"
          effect="dark"
          content="标签仅在下架状态可被删除"
          placement="top-start"
          v-if="tagDetail.on_self === '已上架'"
        >
          <span class="del">删除</span>
        </el-tooltip>
      </div>
    </div>

    <!-- 标签信息 -->
    <div class="box tag_detail_info">
      <div class="floor_top">
        <div class="floor_title">{{ tagDetail.tag_name }}</div>
        <div class="overlay_data">覆盖数据 {{ tagDetail.overlay_data | numThousand }}</div>
      </div>
      <div class="infomations">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="tag_description tag1">
              <p>标签说明:</p>
              <template v-if="!editTag.desc">
                <p>{{ tagDetail.tag_description }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.desc = true"
                  v-if="tagDetail.on_self !== '已上架'"
                />
              </template>
              <template v-else>
                <el-input
                  class="tag_description_editInput"
                  v-model="tag_desc_editVal"
                  placeholder="请输入标签说明"
                ></el-input>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('desc')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('desc')"></i>
                </div>
              </template>
            </div>
            <div class="tag_productMode tag1 tag_mt16">
              <p>生产方式:</p>
              <p>{{ tagDetail.tag_productMode }}</p>
            </div>
            <div class="tag_status tag2 tag_mt16">
              <p>状态:</p>
              <p>{{ tagDetail.on_self }}</p>
            </div>
            <div class="tag_update_time tag1 tag_mt16">
              <p>更新时间:</p>
              <p>{{ tagDetail.update_time }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="tag_classification tag2">
              <p>分类:</p>
              <template v-if="!editTag.class">
                <p>{{ tagDetail.tag_classification }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.class = true"
                  v-if="tagDetail.on_self !== '已上架'"
                />
              </template>
              <template v-else>
                <el-select
                  v-model="tag_class_editVal"
                  placeholder="请选择分类"
                  class="tag_classification_editSelect"
                >
                  <el-option
                    v-for="(item, index) in tagClassOptinos"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('class')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('class')"></i>
                </div>
              </template>
            </div>
            <div class="tag_classification tag1 tag_mt16">
              <p>更新方式:</p>
              <!-- <p>{{ tagDetail.tag_updateMode }}</p> -->
              <template v-if="!editTag.updateWay">
                <p>{{ tagDetail.tag_updateMode | formatTagUpdateWay }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.updateWay = true"
                  v-if="tagDetail.on_self !== '已上架'"
                />
              </template>
              <template v-else>
                <el-select
                  v-model="tag_update_way_edit"
                  placeholder="请选择类型"
                  class="tag_classification_editSelect"
                >
                  <el-option
                    v-for="(item, index) in tagUpdateWayOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('updateWay')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('updateWay')"></i>
                </div>
              </template>
            </div>
            <div class="tag_create_user tag3 tag_mt16">
              <p>创建人:</p>
              <p>{{ tagDetail.create_user }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="tag_attribute tag2">
              <p>属性:</p>
              <p>{{ tagDetail.tag_attribute }}</p>
            </div>
            <div class="tag_value_type tag3 tag_mt16">
              <p>值类型:</p>
              <p>{{ tagDetail.tag_value_type }}</p>
            </div>
            <div class="tag_create_time tag1 tag_mt16">
              <p>创建时间:</p>
              <p>{{ tagDetail.create_time }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 标签数据 -->
    <div class="box tag_detail_content">
      <el-tabs v-model="activeTab" @tab-click="handleClickTab" class="tag_detail_content_tab">
        <el-tab-pane label="标签值" name="tag_val"></el-tab-pane>
        <el-tab-pane label="运行记录" name="tag_optRecord"></el-tab-pane>
      </el-tabs>

      <div class="tag_detail_addVal" v-if="activeTab === 'tag_val'">
        <el-button
          type="primary"
          class="tag_detail_addVal_btn"
          @click="handleAddTagVal"
          :disabled="tagDetail.on_self === '已上架'"
        >
          添加值
        </el-button>
        <div class="tag_detail_finish_time">
          计算完成于:
          <span>{{ tag_value_endTime }}</span>
        </div>
      </div>
      <!-- 值名称搜索关键词 -->
      <div v-if="searchKeyWord.length > 0" class="tag_detail_content_keyword">
        <div class="content_keyword_item" v-for="(item, index) in searchKeyWord" :key="index">
          {{ getFlitersTitle(item.name) }}: {{ item.label }}
          <i class="el-icon-close del" @click="handleDelKeyWords(item.name)"></i>
        </div>
        <el-button v-if="searchKeyWord.length > 0" type="text" @click="deleteAllFilter">
          重置
        </el-button>
      </div>

      <div
        class="tag_detail_content_table"
        :class="{ table_mt24: activeTab !== 'tag_val' }"
        v-show="!requestData"
      >
        <eyao-table
          v-show="!requestData"
          ref="table"
          :table-data="tableData"
          :total-num="totalNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :select-page-size="10"
        >
          <el-table-column
            v-for="(column, index) in configList"
            :key="index"
            :label="column.title"
            :prop="column.property"
            :width="column.width"
            :min-width="column['min-width']"
          >
            <template slot="header" slot-scope="scope">
              <!-- 表头 -->
              <eyao-table-search-header
                v-if="column.showIcon"
                :form-type="column.showIcon"
                :property="column.property"
                :title="column.title"
                :scope="scope"
                @search="searchOpts"
              ></eyao-table-search-header>
              <!-- 无icon -->
              <template v-else>{{ column.title }}</template>
            </template>

            <template slot-scope="scope">
              <!-- 涉及时间字段，format值 -->
              <template v-if="column.format">
                {{ scope.row[column.property] | getFormatTime }}
              </template>

              <!-- 覆盖数据, 千分位格式 -->
              <template v-else-if="column.property === 'data_count' && activeTab === 'tag_val'">
                <span class="overlay_link" @click="handleViewOverlay(scope.row)">
                  {{ scope.row[column.property] | numThousand }}
                </span>
              </template>
              <template v-else-if="column.property === 'exec_type'">
                <span>
                  {{ scope.row[column.property] | handleType }}
                </span>
              </template>
              <!-- 运行记录的运行状态字段 -->
              <template v-else-if="column.property === 'status'">
                <div class="status">
                  <i
                    :class="
                      scope.row[column.property] === 1 || scope.row[column.property] === 2
                        ? 'status-wait'
                        : scope.row[column.property] === 3
                        ? 'status-success'
                        : 'status-error'
                    "
                  ></i>
                  {{ scope.row[column.property] | handleStatus() }}
                </div>
              </template>
              <template v-else>{{ scope.row[column.property] }}</template>
            </template>
          </el-table-column>

          <!-- 操作列  -->
          <el-table-column
            min-width="160"
            fixed="right"
            label="操作"
            v-if="activeTab === 'tag_val'"
          >
            <div slot-scope="scope" class="opt-column">
              <template v-if="activeTab === 'tag_val'">
                <el-button
                  class="btn"
                  type="text"
                  @click="gotoEdit(scope.row, 'edit')"
                  :disabled="tagDetail.on_self === '已上架'"
                >
                  编辑
                </el-button>
                <el-button
                  class="btn"
                  type="text"
                  v-if="scope.row.conditions_connect !== ''"
                  @click="gotoEdit(scope.row, 'preview')"
                >
                  查看规则
                </el-button>

                <el-tooltip class="btn" effect="dark" content="请先编辑规则" placement="top" v-else>
                  <el-button
                    style="color: #96c3fa"
                    type="text"
                    @click="gotoEdit(scope.row, 'preview')"
                  >
                    查看规则
                  </el-button>
                </el-tooltip>
                <el-button
                  class="btn"
                  type="text"
                  @click="handleDelTagVals(scope.row.id)"
                  v-if="tagDetail.on_self !== '已上架'"
                >
                  删除
                </el-button>
                <el-tooltip
                  class="btn"
                  effect="dark"
                  content="标签值仅在下架状态可被删除"
                  placement="top"
                  v-else
                >
                  <el-button style="color: #96c3fa" type="text">删除</el-button>
                </el-tooltip>
              </template>

              <!-- <template v-else>
                <span @click="handleStopRun(scope.row)">终止运行</span>
              </template> -->
            </div>
          </el-table-column>
          <template v-slot:empty>
            <div v-if="totalNum === 0 && !requestData" class="search-no-data">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div>
          </template>
        </eyao-table>
      </div>
      <!-- <div v-if="totalNum === 0 && !requestData" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->
      <div class="listLoading" v-if="requestData">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </div>

    <el-drawer
      title="覆盖数据"
      :visible.sync="showOverlayDrawer"
      direction="rtl"
      :size="960"
      class="overlayData-drawer-warp"
      :before-close="closeOverlayDrawer"
    >
      <div class="overlayData-drawer-tagNum">
        标签值:
        <span>{{ overlayData_tagName }}</span>
      </div>
      <eyao-table
        v-show="!requestOverlay"
        class="overlayDataDrawer_table"
        :table-data="overlayData_tableData"
        :total-num="overlayData_totalNum"
        :select-page-size="10"
        @filter-change="filterChange"
        @size-change="overlayDataSizeChange"
        @current-change="overlayDataCurrentChange"
      >
        <el-table-column
          v-for="column in overlayConfigList"
          :key="`${$route.name}-overlayData-${column.property}`"
          :label="column.title"
          :prop="column.property"
          :width="column.width"
        >
          <template slot-scope="scope">
            <!-- 涉及时间字段，format值 -->
            <template v-if="column.format">
              <template v-if="column.property === 'establishment_date'">
                {{ scope.row[column.property] | getYMD }}
              </template>
              <template v-else>{{ scope.row[column.property] | getFormatTime }}</template>
            </template>

            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>
        <template v-slot:empty>
          <div v-if="overlayData_totalNum === 0 && !requestOverlay" class="search-no-data">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>

      <!-- <div v-if="overlayData_totalNum === 0 && !requestOverlay" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->

      <div class="listLoading" v-if="requestOverlay">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </el-drawer>

    <!-- 添加编辑标签值 -->
    <el-drawer
      :title="
        tagDrawerType === 'add'
          ? '添加标签值'
          : tagDrawerType === 'edit'
          ? '编辑标签值'
          : '规则详情'
      "
      :visible.sync="tagDrawer"
      direction="rtl"
      :size="640"
      class="addTagval-drawer-warp"
      :before-close="closeAddTagDrawer"
    >
      <div class="content">
        <div>
          <div class="top">
            <p class="title">值名称</p>
            <el-tooltip
              class="item"
              effect="dark"
              content="同一标签下的值名称不能重复"
              placement="top-start"
            >
              <img src="./img/trip.svg" alt="" />
            </el-tooltip>
          </div>
          <el-input
            :disabled="tagDrawerType === 'preview'"
            class="inp"
            v-model="tagInput"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 规则条件 -->
        <div class="rule">
          <p class="title">规则条件</p>
          <div class="select-rule">
            <div v-for="(obj, index) in ruleData" :key="index" class="select-rule-item">
              <div class="num">{{ index + 1 }}</div>
              <!-- 字段名称 -->
              <el-select
                :disabled="tagDrawerType === 'preview'"
                class="select-field"
                v-model="obj.property"
                placeholder="请选择字段"
                clearable
                filterable
                @change="
                  val => {
                    handleOneCondition(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in titleOpts"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>

              <!-- 运算符 -->
              <el-select
                :disabled="tagDrawerType === 'preview'"
                v-if="obj.type === 'select' || obj.type === 'cascader' || obj.type === ''"
                class="select-operator"
                v-model="obj.operator"
                placeholder="选择运算符"
                clearable
                filterable
                @change="
                  val => {
                    handleTwoCondition(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in operator2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- type为input的情况 -->
              <el-select
                :disabled="tagDrawerType === 'preview'"
                v-else-if="obj.type === 'input'"
                class="select-operator"
                v-model="obj.operator"
                clearable
                filterable
                placeholder="选择运算符"
                @change="
                  val => {
                    handleTwoCondition(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in operator1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- type为日期、数值的情况 -->
              <el-select
                :disabled="tagDrawerType === 'preview'"
                v-else-if="obj.type === 'date' || obj.type === 'num'"
                class="select-operator"
                v-model="obj.operator"
                clearable
                filterable
                placeholder="选择运算符"
                @change="
                  val => {
                    handleTwoCondition(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in operator3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>

              <!-- 查询值 -->
              <el-select
                :disabled="tagDrawerType === 'preview'"
                v-if="obj.type === 'select'"
                class="select-value"
                v-model="obj.queryValue"
                placeholder="请选择查询值"
                multiple
                filterable
                @change="
                  val => {
                    handleThreeCondition(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in queryValOpts[obj.property]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-cascader
                :options="cascaderData[obj.property]"
                :disabled="tagDrawerType === 'preview'"
                v-else-if="obj.type === 'cascader'"
                class="select-value mt4"
                v-model="obj.queryValue"
                :ref="`cascaderMallCatergory-${index}`"
                :props="dataProps[obj.property]"
                placeholder="请选择查询值"
                @change="
                  val => {
                    hanldeCascaderVal(val, index);
                  }
                "
              ></el-cascader>
              <el-date-picker
                :disabled="tagDrawerType === 'preview'"
                v-else-if="obj.type === 'date'"
                class="select-value datePickerVal"
                v-model="obj.queryValue"
                type="date"
                placeholder="选择日期"
                @change="
                  val => {
                    hanldePickerVal(val, index);
                  }
                "
              ></el-date-picker>
              <el-input
                :disabled="tagDrawerType === 'preview'"
                v-else-if="obj.type === 'num'"
                class="select-value"
                v-model="obj.queryValue"
                type="number"
                placeholder="请填写查询值"
              ></el-input>
              <el-input
                :disabled="tagDrawerType === 'preview'"
                v-else
                type="textarea"
                class="select-value"
                v-model="obj.queryValue"
                placeholder="请填写查询值"
                :autosize="{ maxRows: 3 }"
                @input="
                  val => {
                    checkSpecificKey(val, index);
                  }
                "
              ></el-input>
              <!-- 删除规则条件 -->
              <i
                class="el-icon-minus"
                v-if="ruleData.length > 1 && tagDrawerType !== 'preview'"
                @click="delRuleCondition(index)"
              ></i>
            </div>
            <div class="add-rule" @click="addRuleCondition" v-if="tagDrawerType !== 'preview'">
              <i class="el-icon-plus" />
              <p>添加条件</p>
            </div>
          </div>
        </div>
        <div class="condition">
          <div class="top">
            <p class="title">条件公式</p>
            <el-tooltip
              class="item"
              effect="dark"
              content="支持and、or和()，请使用半角字符"
              placement="top-start"
            >
              <img src="./img/trip.svg" alt="" />
            </el-tooltip>
          </div>
          <div class="operate">
            <el-button
              class="generate"
              :disabled="tagDrawerType === 'preview'"
              @click="generateFormula"
            >
              生成公式
            </el-button>
            <el-input
              :disabled="tagDrawerType === 'preview'"
              class="formula"
              placeholder="点击左侧自动生成公式或自定义输入公式"
              v-model="conditionalFormula"
              @change="handleCheckFormula"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="drawer__footer" v-if="tagDrawerType !== 'preview'">
        <p class="overlaying">
          <span v-if="showTryCal">预计覆盖数据: {{ numCoverage }}</span>
        </p>
        <div>
          <el-button class="btn-gray" @click="closeAddTagDrawer">取消</el-button>
          <el-button class="btn-gray btn2" @click="handleDataTryCal">覆盖数据试算</el-button>
          <el-button class="btn-blue" @click="saveAs">保存</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 查看规则 -->
    <!-- <el-drawer size="480px" title="规则详情" :visible.sync="ruleDrawer">
      <div class="rule-name">
        <span class="tag-key">标签值：</span>
        <span class="tag-val">中</span>
      </div>
      <div class="rule-name">
        <span class="tag-key">标签规则：</span>
        <div></div>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import { dateFormat, uniqueArr, getDate } from '@/utils/util';
import tagValConfig from './config/tagValue';
import optRecordConfig from './config/optRecord';
import hcoConfig from './config/hcoConfig';
import hcpConfig from './config/hcpConfig';
import yiyuanConfig from '../data-list/config/hospital';
import dealerConfig from '../data-list/config/dealer';
import shopConfig from '../data-list/config/shop';
import doctorConfig from '../data-list/config/doctor';
import hospitalLabelConfig from '../data-list/labelConfig/hospital';
import doctorLabelConfig from '../data-list/labelConfig/doctor';
import { hcoCustom } from '../data-list/customField';
import * as apis from '@/api/data';
// import { getAdminDistrict } from '@/api/cascader-api';
import { SPECIAL_CHAR } from '@/utils/constant';
import { GetAllProvinceCityInfo, GetAllDepInfo } from '@/api/trade-search';
import {
  EditTag,
  GetTagValues,
  GetTagSyncLog,
  ShelfTag,
  GetTagDetail,
  ExecTagSync,
  AddTagValues,
  EditTagValues,
  GetAllOrgTypes,
  GetTagValueDataCount,
  DeleteTag,
  DeleteTagValues,
} from '@/api/tag.js';

// const orgDataTypes = [
//   '医院',
//   '基层医疗卫生机构',
//   '专业公共卫生机构',
//   '其他医疗卫生机构',
//   '经销商',
//   '药店',
//   '医疗器械',
//   '生产企业',
//   '其他分类',
// ];

export default {
  name: 'TagManageDetail',
  components: {
    eyaoTable,
    eyaoTableSearchHeader,
  },
  filters: {
    formatTagUpdateWay(val) {
      if (val === 1) {
        return '手动更新';
      }
      if (val === 2) {
        return '定时更新';
      }
      return '';
    },
    // 数字千分位隔开
    numThousand(value) {
      return Number(value).toLocaleString();
    },

    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
    getYMD(value) {
      if (value && !value.startsWith('0')) {
        const date = new Date(value);
        let year = date.getFullYear();
        year = year < 10 ? `0${year}` : year;
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
      }
      return '';
    },
    handleStatus(val) {
      switch (val) {
        case 1:
          return '排队中';
        case 2:
          return '执行中';
        case 3:
          return '已完成';
        case 4:
          return '任务失败';
      }
    },
    handleType(val) {
      if (val === 1) {
        return '自动';
      }
      if (val === 2) {
        return '手动';
      }
    },
  },
  data() {
    return {
      tagUpdateWayOptions: [
        { label: '手动更新', value: 1 },
        { label: '定时更新', value: 2 },
      ],
      numCoverage: 0,
      editID: '',
      listedDisabled: false,
      searchKeyWord: [],
      tagDetail: {},
      hcoTag: [
        { label: '基本属性', value: 'basic' },
        { label: '位置属性', value: 'position' },
        { label: '经营属性', value: 'business' },
        { label: '诊断属性', value: 'diagnosis' },
        { label: '腾讯特色', value: 'special' },
      ],
      hcpTag: [
        { label: '基本属性', value: 'basic' },
        { label: '工作属性', value: 'work' },
        { label: '执业属性', value: 'practice' },
        { label: '学术属性', value: 'academic' },
        { label: '腾讯特色', value: 'special' },
      ],
      // 编辑标签info
      editTag: {
        desc: false,
        class: false,
        updateWay: false,
      },
      // 标签的编辑值
      tag_update_way_edit: null,
      tag_desc_editVal: '',
      tag_class_editVal: '',
      // 标签值与运行记录tab
      activeTab: 'tag_val',
      requestData: true,
      tag_value_endTime: '',
      // 表格数据
      tableData: [],
      totalNum: 0,
      configList: [],
      configLabel: [],
      currentDataPage: 1,
      pageDataSize: 10,
      // 覆盖数据抽屉
      requestOverlay: true,
      showOverlayDrawer: false,
      overlayData_tableData: [],
      overlayData_totalNum: 0,
      overlayConfigList: [],
      overlayConfigLabel: [],
      overlayCurPage: 1,
      overlayPageSize: 10,
      overlayId: '',
      overlayData_tagName: '',
      propertyName: [
        'type_name',
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'standard_dept_name',
        'professional_title',
        'org_type',
        'administrative_title',
        'academic_title',
      ],
      // 添加标签值抽屉
      tagDrawer: false,
      tagDrawerType: '',
      tagInput: '',
      // 查看规则抽屉
      ruleDrawer: false,
      // 规则条件
      ruleData: [{ property: '', operator: '', queryValue: '', type: '', title: '' }],
      ruleConfig: [],
      configObj: {},
      titleOpts: [],
      queryValOpts: {},
      dataProps: {
        admin_division: {
          multiple: true,
          value: 'code',
          label: 'name',
          checkStrictly: true,
        },
        type_name: {
          multiple: true,
          value: 'code',
          label: 'name',
          checkStrictly: true,
        },
        standard_dept_name: {
          multiple: true,
          value: 'code',
          label: 'name',
          checkStrictly: true,
        },
      },
      cascaderData: {
        admin_division: [],
        type_name: [],
        standard_dept_name: [],
      },
      // 文本
      operator1: [
        { value: 1, label: '包含' },
        { value: 15, label: '不包含' },
      ],
      // 下拉框
      operator2: [
        { value: 2, label: '等于' },
        { value: 13, label: '不等于' },
      ],
      // 数值、日期、时间
      operator3: [
        { value: 9, label: '大于等于' },
        { value: 11, label: '小于等于' },
      ],
      // 条件公式
      conditionalFormula: '',
      // 是否展示试算结果
      showTryCal: false,
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 标签的编辑值-分类
    tagClassOptinos() {
      if (this.tagDetail.type === 'hcp') {
        return this.hcpTag;
      }
      return this.hcoTag;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'tag-manage-detail') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 获取所有类型细分
    getTypeList() {
      GetAllOrgTypes().then(res => {
        console.log('类型细分', res);
        this.cascaderData.type_name = res.orgs;
      });
    },
    // 过滤掉空的children
    formatData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children == null || data[i].children.length == 0) {
          data[i].children = undefined;
        } else if (data[i].children.length > 1) {
          this.formatData(data[i].children);
        }
      }
      return data;
    },
    // 获取科室
    getStandard() {
      GetAllDepInfo().then(res => {
        console.log('科室的数据', res);
        this.cascaderData.standard_dept_name = res.datas;
        this.cascaderData.standard_dept_name = this.formatData(
          this.cascaderData.standard_dept_name,
        );
      });
    },
    // 获取地理位置
    getAdress() {
      GetAllProvinceCityInfo().then(res => {
        console.log('地址位置', res);
        this.cascaderData.admin_division = res.datas;
      });
    },
    init() {
      this.tableData = [];
      this.requestData = true;
      // this.ruleData = [
      //   {
      //     operator: 2,
      //     property: 'is_medical_insurance',
      //     queryValue: ['1', '2'],
      //     title: '是否医保',
      //     type: 'select',
      //   },
      // ];
      this.dealTagQuery();
    },

    dealTagQuery() {
      GetTagDetail({ id: [this.$route.query.id] }).then(res => {
        this.tag_value_endTime = res.details[0]?.last_cal_time;
        const tagQuery = {
          id: res.details[0].id,
          type: res.details[0].type1 === 't_hco' ? 'hco' : 'hcp',
          overlay_data: res.details[0].data_count, // 覆盖数据量
          tag_name: res.details[0].name, // 标签名称
          tag_description: res.details[0].description, // 标签说明
          tag_classification: res.details[0].type2, // 标签分类
          tag_attribute: res.details[0].attribute, // 标签属性
          tag_productMode: res.details[0].create_way, // 生产方式
          tag_updateMode: res.details[0].update_way, // 更新方式
          tag_value_type: res.details[0].value_type, // 值类型
          status: res.details[0].sync_status, // 状态
          on_self: res.details[0].on_self, // 上架
          create_user: res.details[0].create_user, // 创建人
          create_time: res.details[0].create_time, // 创建时间
          update_time: res.details[0].update_time, // 更新时间
        };
        this.tagDetail = tagQuery;
        if (tagQuery.tag_value_type === '多值型') {
          this.tagDetail.tag_value_type = '多值文本型';
        }
        if (tagQuery.tag_value_type === '数值型') {
          this.tagDetail.tag_value_type = '整数型';
        }
        this.tag_desc_editVal = tagQuery.tag_description;
        this.tag_class_editVal = tagQuery.tag_classification;
        this.tag_update_way_edit = this.tagDetail.tag_updateMode;
        const createWay = ['规则生成', '算法生成', '人工录入'];
        this.tagDetail.tag_productMode = createWay[this.tagDetail.tag_productMode - 1];
        // const updateWay = ['手动更新', '例行化更新'];
        // this.tagDetail.tag_updateMode = updateWay[this.tagDetail.tag_updateMode - 1];

        const onSelf = ['待上架', '已上架', '已下架'];
        this.tagDetail.on_self = onSelf[this.tagDetail.on_self - 1];
        console.log(this.tagDetail, 'tagDetail');
        this.handleClickTab();
        this.getTagVal();

        if (this.tagDetail.type === 'hco') {
          this.getAdress();
          this.getTypeList();
        }
        if (this.tagDetail.type === 'hcp') {
          this.getStandard();
        }
      });
    },
    // 查询是否存在未维护的标签值
    getTagVal() {
      const req = {
        id: this.$route.query.id,
        tag_value_name: '',
        completed: 2,
        create_user: '',
        index: 1,
        page_size: 1,
      };
      GetTagValues(req).then(res => {
        if (res.tag_values.length) {
          this.listedDisabled = true;
        } else {
          this.listedDisabled = false;
        }
      });
    },
    // 确认编辑标签信息
    comfirmEditTag(tab) {
      this.editTag[tab] = false;
      if (tab === 'desc') {
        this.tagDetail.tag_description = this.tag_desc_editVal;
      } else if (tab === 'updateWay') {
        this.tagDetail.tag_updateMode = this.tag_update_way_edit;
      } else {
        const val = this.tagClassOptinos.find(item => item.value === this.tag_class_editVal).label;
        this.tagDetail.tag_classification = val;
      }

      const params = {
        id: this.tagDetail.id,
        name: this.tagDetail.tag_name,
        description: this.tagDetail.tag_description,
        type2: this.tagDetail.tag_classification,
        update_way: this.tagDetail.tag_updateMode,
      };
      console.log(params);
      EditTag(params).then(() => {
        this.$message.success('编辑成功');
      });
    },

    // 取消编辑标签信息
    closeEditTag(tab) {
      this.editTag[tab] = false;
      if (tab === 'desc') {
        this.tag_desc_editVal = this.tagDetail.tag_description;
      } else if (tab === 'updateWay') {
        this.tag_update_way_edit = this.tagDetail.tag_updateMode;
      } else {
        this.tag_class_editVal = this.tagDetail.tag_classification;
      }
    },

    // 标签上架
    handleListed(opt = 1) {
      const req = {
        id: this.$route.query.id,
        operation: opt,
      };
      ShelfTag(req)
        .then(res => {
          console.log('上下架', res);
          if (opt === 1) {
            this.$message.success('上架成功');
          } else {
            this.$message.success('下架成功');
          }
          this.dealTagQuery();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 标签删除
    handleDel() {
      const req = {
        tag_id: this.$route.query.id,
      };
      this.$confirm('删除标签将中止未完成的计算，并清除已标记数据的标签，是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteTag(req)
          .then(res => {
            console.log(res);
            this.$message.success('删除成功');
            this.g_tourl(`/dashboard/tag-management`);
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },

    // 标签下架
    hanldeShelfOff() {
      this.$confirm('下架后标签将不在主数据中展示,并中止未完成的计算,是否确定要下架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.handleListed(2);
      });
    },

    // 手动运行
    handleRun() {
      ExecTagSync({ id: this.$route.query.id, is_stop: 0 }).then(res => {
        console.log(res);
        if (res.is_exe === 0) {
          this.$message.success('手动运行中...');
        } else {
          console.log(22);
          this.$confirm('当前计算仍未完成，是否终止任务并创建新任务?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            ExecTagSync({ id: this.$route.query.id, is_stop: 1 }).then(() => {
              this.$message.success('终止成功');
            });
          });
        }
      });
    },

    // tab切换
    handleClickTab(tab) {
      this.requestData = true;
      this.pageDataSize = 10;
      this.currentDataPage = 1;
      this.activeTab = tab?.name ? tab.name : 'tag_val';
      this.filterInit();

      if (this.activeTab === 'tag_val') {
        this.requestTagData();
        this.initRuleConfig();
        this.getOptions();
      } else {
        this.getTagRunRecord();
      }
    },

    // 获取标签运行记录
    getTagRunRecord() {
      this.configList = optRecordConfig;
      const req = {
        id: this.$route.query.id,
        index: this.currentDataPage,
        page_size: this.pageDataSize,
      };
      GetTagSyncLog(req).then(res => {
        console.log('运行记录', res);
        this.tableData = res.sync_log;
        this.totalNum = res.total;
        setTimeout(() => {
          this.requestData = false;
        }, 500);
      });
    },

    // 请求标签值数据
    requestTagData() {
      console.log(this.filterObject);
      this.configList = tagValConfig;
      const { name } = this.filterObject;
      const params = {
        id: this.$route.query.id,
        tag_value_name: name ? name.value : '', // 标签值名称，用于模糊搜索，空字符串不做筛选
        completed: 0, // 该标签值规则是否已维护，1-已维护，2-未维护，0-不做筛选
        index: this.currentDataPage,
        page_size: this.pageDataSize,
      };
      GetTagValues(params).then(res => {
        if (res?.tag_values) {
          const datalist = res.tag_values;
          this.tableData = datalist.map(item => {
            return { ...item, update_time: item.update_time.replace(/-/g, '/') };
          });
          this.totalNum = res.total;
          setTimeout(() => {
            this.requestData = false;
          }, 500);
        }
      });
    },

    initRuleConfig() {
      if (this.tagDetail.type === 'hco') {
        this.ruleConfig = hcoConfig;
      } else {
        this.ruleConfig = hcpConfig;
      }
      this.ruleConfig.forEach(item => {
        this.configObj[item.property] = item;

        // 添加标签值的标签名称title
        this.titleOpts.push({
          value: item.property,
          label: item.title,
          search: item.search,
        });
      });
      console.log('标签值的标签名称title', this.titleOpts);
    },

    // 标签值-添加值
    handleAddTagVal() {
      this.tagDrawer = true;
      this.tagDrawerType = 'add';
    },

    // 关闭添加值抽屉
    closeAddTagDrawer() {
      this.tagDrawer = false;
      this.ruleData = [{ property: '', operator: '', queryValue: '', type: '', title: '' }];
      this.tagInput = '';
      this.conditionalFormula = '';
    },

    // 增加规则条件
    addRuleCondition() {
      if (this.ruleData.length < 10) {
        this.ruleData.push({
          property: '',
          operator: '',
          queryValue: '',
          type: '',
          title: '',
        });
      } else {
        this.$message.error('最多只能添加10个查询条件');
      }
      console.log(this.ruleData);
    },

    // 删除搜索条件
    delRuleCondition(idx) {
      this.ruleData.splice(idx, 1);
    },

    // 字段名称选择值
    handleOneCondition(val, index) {
      console.log(val, index);
      if (val) {
        this.ruleData.forEach((ele, idx) => {
          this.ruleData[index].type = this.configObj[this.ruleData[index].property]?.search;
          this.ruleData[index].title = this.configObj[this.ruleData[index].property]?.title;

          // 文本框，1-包含，15-不包含
          // 下拉框、级联框，2-字符串等于，13-字符串不等于,     3-数字等于, 14-数字不等于，
          // 时间、数值，9-数值大于等于，11-数值小于等于，  10-时间大于等于，12-时间小于等于，

          // 每次更新字段名称时，默认操作符
          if (index === idx && ele.type === 'input') {
            this.ruleData[idx].operator = 1; // 文本默认包含
          } else if (index === idx && (ele.type === 'date' || ele.type === 'num')) {
            this.ruleData[idx].operator = 9; // 时间默认大于等于
          } else if (index === idx && (ele.type === 'select' || ele.type === 'cascader')) {
            this.ruleData[idx].operator = 2; // 标准值默认等于
          }

          // 重新更新字段名称时，删掉之前的查询值
          if (index === idx) {
            this.ruleData[idx].queryValue = '';
          }
        });
      } else {
        this.ruleData[index].operator = '';
        this.ruleData[index].queryValue = '';
      }

      console.log(this.ruleData);
    },

    // 操作符选择值
    handleTwoCondition(val, index) {
      this.ruleData[index].operator = val;
      console.log(this.ruleData);
    },

    // 查询值选择值
    handleThreeCondition(val, index) {
      console.log(val, index);
      this.ruleData[index].queryValue = val;
      console.log(this.ruleData);
    },

    // 查询值为级联
    hanldeCascaderVal(val, index) {
      console.log(val, index);
      // this.ruleData[index].queryValue = val.join(',');
      console.log(this.ruleData);
    },

    // 查询值为日期
    hanldePickerVal(val, index) {
      console.log(val, index);
      const { year, month, date } = getDate(new Date(val));
      this.ruleData[index].queryValue = `${year}-${month}-${date}`;
    },

    // 查询值为input，禁止输入特殊符号
    checkSpecificKey(val, index) {
      console.log(val, index);
      if (SPECIAL_CHAR.test(val)) {
        this.$message.error('不能输入特殊字符');
        this.ruleData[index].queryValue = '';
      }
      console.log(this.ruleData);
    },

    generateFormula() {
      console.log(this.ruleData);
      this.ruleData.forEach(item => {
        if (
          item.property !== '' &&
          item.operator !== '' &&
          item.queryValue !== '' &&
          item.queryValue.length
        ) {
          this.conditionalFormula = this.ruleData.map((_, idx) => idx + 1).join('and');
        } else {
          this.$message.error('规则条件填写不完整');
          this.conditionalFormula = '';
        }
      });
    },

    // 验证公式
    handleCheckFormula(val) {
      const reg = /^[0-9andor()]*$/;
      if (!reg.test(val)) {
        this.$message.error('只能用and or ()');
      }
    },

    // 覆盖数据试算前验证
    handleDataTryCal() {
      const flag = this.hanldeVerifyCon();

      // 验证通过，进行覆盖数据试算
      if (flag) this.dealTryCalData();
    },

    dealTryCalData() {
      console.log('验证通过,进行覆盖数据试算');
      this.showTryCal = true;
      const type = this.tagDetail.type === 'hco' ? 't_hco' : 't_hcp';
      this.getNumberCoverage(type);
    },

    saveAs() {
      console.log('保存');
      if (this.tagInput === '') {
        this.$message.error('请填写值名称');
        return;
      }
      const flag = this.hanldeVerifyCon();

      // 验证通过，进行保存操作
      if (flag) {
        if (this.tagDrawerType === 'add') {
          this.comfirmSaveAs();
        }
        if (this.tagDrawerType === 'edit') {
          this.comfirmEdit();
        }
        this.requestTagData();
        // this.getTagVal();
      }
    },

    dealConditionData() {
      // 1-包含，15-不包含
      // 2-字符串等于，13-字符串不等于,     3-数字等于, 14-数字不等于，
      // 9-数值大于等于，11-数值小于等于，  10-时间大于等于，12-时间小于等于，
      const speciaType = ['is_medical_insurance', 'in_doubt', 'gender'];
      const ruleParams = [];
      this.ruleData.forEach(ele => {
        if (ele.type === 'input' || ele.type === 'num') {
          const formatInp = ele.queryValue.split('\n');
          ruleParams.push({ field: ele.property, operator: ele.operator, values: formatInp });
        } else if (ele.type === 'cascader') {
          // const formatInp = ele.queryValue.split(',');
          let fieldName = 'admin_division';
          if (ele.property === 'type_name') {
            fieldName = 'type_detail';
          }
          if (ele.property === 'standard_dept_name') {
            fieldName = 'department_detail';
          }
          // console.log('此时的',ele.queryValue);
          const arr = [];
          ele.queryValue.forEach(item => {
            console.log('item', item);
            arr.push(item.join(','));
          });
          ruleParams.push({
            field: fieldName,
            operator: ele.operator,
            values: arr,
          });
        } else if (ele.type === 'select') {
          let ope = '';
          // 数值的等于
          if (speciaType.includes(ele.property) && ele.operator === 2) {
            ope = 3;
          }
          // 数值的不等于
          if (speciaType.includes(ele.property) && ele.operator === 13) {
            ope = 14;
          }
          ruleParams.push({
            field: ele.property,
            operator: ope ? ope : ele.operator,
            values: ele.queryValue,
          });
        } else {
          const dateVal = ele.queryValue.split('\n');
          // 处理日期时间
          let ope = '';
          // 时间大于等于
          if (ele.operator === 9) {
            ope = 10;
          }
          // 时间小于等于
          if (ele.operator === 11) {
            ope = 12;
          }

          ruleParams.push({
            field: ele.property,
            operator: ope ? ope : ele.operator,
            values: dateVal,
          });
        }
      });

      return ruleParams;
    },
    // 覆盖数据量试算
    getNumberCoverage(type) {
      const req = {
        type1: type,
        conditions: this.dealConditionData(),
        conditions_connect: this.conditionalFormula,
      };
      GetTagValueDataCount(req).then(res => {
        console.log('覆盖试算量', res);
        this.numCoverage = res.data_count;
      });
    },
    comfirmSaveAs() {
      console.log('验证通过,进行保存');
      const params = {
        id: this.$route.query.id, // 标签id
        tag_value_name: this.tagInput,
        conditions: this.dealConditionData(), // 规则条件
        conditions_connect: this.conditionalFormula, // 规则公式
      };
      console.log(params);

      AddTagValues(params)
        .then(res => {
          if (res?.tag_value_id) {
            this.closeAddTagDrawer();
            this.requestTagData();
            this.$message.success('成功新建标签值');
            this.getTagVal();
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
        });
    },
    comfirmEdit() {
      const params = {
        tag_value_id: this.editID, // 标签id
        tag_value_name: this.tagInput,
        conditions: this.dealConditionData(), // 规则条件
        conditions_connect: this.conditionalFormula, // 规则公式
      };
      console.log(params);

      EditTagValues(params)
        .then(() => {
          this.closeAddTagDrawer();
          this.requestTagData();
          this.$message.success('成功编辑标签值');
          this.getTagVal();
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
        });
    },

    // 验证条件
    hanldeVerifyCon() {
      const arr = [];
      this.ruleData.forEach(ele => {
        if (ele.property && ele.operator && ele.queryValue) {
          arr.push(ele);
        }
      });
      if (arr.length !== this.ruleData.length || this.ruleData.length === 0) {
        this.$message.error('规则条件填写不完整');
        return false;
      }
      if (this.conditionalFormula === '') {
        this.$message.error('请填写条件公式');
        return false;
      }
      const arr2 = [];
      for (let i = 0; i < this.conditionalFormula.length; i++) {
        if (this.conditionalFormula.charCodeAt(i) > 128) arr2.push(i);
      }
      if (arr2.length > 0) {
        this.$message.error('公式只能包含半角字符');
        return false;
      }
      return true;
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage() || {};
      this.updateFilterList();
    },

    // 搜索表头关键字
    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
    },

    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.currentDataPage = 1;
      if (this.activeTab === 'tag_val') {
        this.requestTagData();
      } else {
        this.getTagRunRecord();
      }
    },

    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (JSON.stringify(this.filterObject[key]) !== '{}') {
          list.push({ name: key, label: this.filterObject[key].label });
        }
      });
      this.searchKeyWord = list;
      console.log(this.searchKeyWord, '筛选条件keyword');
      this.setStorage();
    },

    // 展示筛选条件的标题
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    setStorage() {
      const filterData = this.getStorage();
      if (this.activeTab === 'tag_val') {
        filterData['tagManage-tagVal'] = this.filterObject;
        localStorage.setItem('tagManage-tagVal-filter', JSON.stringify(this.filterObject));
      } else {
        filterData['tagManage-optRecord'] = this.filterObject;
        localStorage.setItem('tagManage--optRecord-filter', JSON.stringify(this.filterObject));
      }
    },
    getStorage() {
      let str = null;
      if (this.activeTab === 'tag_val') {
        str = localStorage.getItem('tagManage-tagVal-filter');
      } else {
        str = localStorage.getItem('tagManage--optRecord-filter');
      }
      return str ? JSON.parse(str) : {};
    },

    // 删除关键词
    handleDelKeyWords(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.currentDataPage = 1;
      if (this.activeTab === 'tag_val') {
        this.requestTagData();
      } else {
        this.getTagRunRecord();
      }
    },

    deleteAllFilter() {
      this.searchKeyWord = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      this.currentDataPage = 1;
      if (this.activeTab === 'tag_val') {
        this.requestTagData();
        localStorage.removeItem('tagManage-tagVal-filter');
      } else {
        this.getTagRunRecord();
        localStorage.removeItem('tagManage--optRecord-filter');
      }
    },

    // 标签值-覆盖数据
    handleViewOverlay(row) {
      console.log(row, '标签值-覆盖数据');
      if (row.data_count !== '0' && row.data_count !== '') {
        this.showOverlayDrawer = true;
        this.overlayData_tagName = row.name;
        this.overlayId = row.id;
        if (this.tagDetail.type === 'hco') {
          this.overlayConfigList = this.formatFieldOrder();
          this.overlayConfigLabel = hospitalLabelConfig;
        } else {
          this.overlayConfigList = doctorConfig;
          this.overlayConfigLabel = doctorLabelConfig;
        }
        this.requestOverData();
      }
    },

    // 请求覆盖数据列表
    requestOverData() {
      console.log('请求覆盖数据列表');
      this.requestOverlay = true;
      const params = {
        main_data: this.tagDetail.type === 'hco' ? 't_hco' : 't_hcp',
        index: this.overlayCurPage,
        page_size: this.overlayPageSize,
        search_fields: this.dealDivisionParam(),
        data_array: [],
        SearchOptions: [
          {
            field_name: 'tag_value_ids',
            data_name: this.tagDetail.type === 'hco' ? 't_hco' : 't_hcp',
            type: 1,
            key: this.overlayId,
          },
        ],
      };

      apis.SearchMainDataByType(params).then(res => {
        if (res?.data) {
          const dataList = res.data.map(item => JSON.parse(item));
          this.overlayData_totalNum = res.total_num;

          if (this.tagDetail.type === 'hco') {
            // 处理行政区划、二级分类、类型细分
            this.dealFieldData(dataList);
          } else {
            // 处理标准科室
            this.dealStandardData(dataList);
          }

          this.dealDataLabel(dataList);
          this.overlayData_tableData = dataList;
          console.log(this.overlayData_tableData, '覆盖数据list');

          setTimeout(() => {
            this.requestOverlay = false;
          }, 500);
        }
      });
    },

    // 处理请求字段
    dealDivisionParam() {
      let allProperty = this.overlayConfigList.map(item => item.property);

      // 医生，标准科室
      if (this.tagDetail.type === 'hcp') {
        allProperty = [...allProperty, 'standard_dept_id', 'id', 'hcp_code', 'hco_id'];
      }

      // 机构，行政区划、二级分类、类型细分
      if (this.tagDetail.type === 'hco') {
        const arr = [
          'province_name',
          'city_name',
          'district_name',
          'town_name',
          'village_name',
          'province_code',
          'city_code',
          'district_code',
          'town_code',
          'village_code',
        ];
        const hcoTypeArr = [
          'hco_type1',
          'hco_type_name1',
          'hco_type2',
          'hco_type_name2',
          'hco_type3',
          'hco_type_name3',
          'hco_type4',
          'hco_type_name4',
          'hco_type5',
          'hco_type_name5',
        ];
        allProperty = [...allProperty, ...hcoTypeArr, ...arr, 'id', 'hco_code'];
      }

      return allProperty;
    },

    dealFieldData(dataList) {
      if (dataList.length === 0) return;
      dataList.forEach(ele => {
        const adminDivision = `${ele.province_name}/${ele.city_name}/${ele.district_name}/${ele.town_name}/${ele.village_name}`;
        ele.admin_division = this.delEndStr(adminDivision);

        const typeName = `${ele.hco_type_name3}/${ele.hco_type_name4}/${ele.hco_type_name5}`;
        ele.type_name = this.delEndStr(typeName);
        ele.hco_type = ele.hco_type_name2;
      });
    },
    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },
    dealStandardData(dataList) {
      if (dataList.length === 0) return;
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
      });
    },

    // 关闭覆盖数据抽屉
    closeOverlayDrawer() {
      this.showOverlayDrawer = false;
      this.overlayData_tableData = [];
      this.overlayData_totalNum = 0;
      this.overlayConfigList = [];
      this.overlayCurPage = 1;
      this.overlayPageSize = 10;
    },
    // 删除标签值
    handleDelTagVals(id) {
      const req = {
        tag_value_id: id,
      };
      this.$confirm('删除标签值将中止未完成的计算，并清除已标记数据的标签，是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteTagValues(req)
          .then(res => {
            console.log(res);
            this.$message.success('删除成功');
            this.requestTagData();
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },

    // 标签值-编辑与查看
    gotoEdit(row, status) {
      console.log(row, '标签值-编辑/查看');
      this.tagDrawer = true;
      this.tagDrawerType = status;
      this.editID = row.id;

      if (!row.conditions.length && row.conditions_connect === '') {
        this.tagInput = row.name;
        this.ruleData = [{ property: '', operator: '', queryValue: '', type: '', title: '' }];
        return;
      }

      const data = row.conditions;
      this.tagInput = row.name;
      this.conditionalFormula = row.conditions_connect;
      console.log('当前配置', this.ruleConfig);
      const editData = [];

      this.ruleConfig.forEach(item => {
        data.forEach(val => {
          if (item.property === val.field && (item.search === 'input' || item.search === 'num')) {
            editData.push({
              property: item.property,
              operator: val.operator,
              queryValue: val.values.join('\n'),
              type: item.search,
              title: item.title,
            });
          }
          if (item.property === val.field && item.search === 'select') {
            let opt = '';
            if (val.operator === 3) {
              opt = 2;
            }
            if (val.operator === 14) {
              opt = 13;
            }
            editData.push({
              property: item.property,
              operator: opt ? opt : val.operator,
              queryValue: val.values,
              type: item.search,
              title: item.title,
            });
          }
          if (val.field === 'type_detail' && item.property === 'type_name') {
            // const value = val.values.reduce(function (a, b) {
            //   return a.concat(b);
            // });
            const arr = [];
            val.values.forEach(item => {
              arr.push(item.split(','));
            });
            editData.push({
              property: item.property,
              operator: val.operator,
              queryValue: arr,
              type: item.search,
              title: item.title,
            });
          }
          if (val.field === 'admin_division' && item.property === 'admin_division') {
            // const value = val.values.reduce(function (a, b) {
            //   return a.concat(b);
            // });
            const arr = [];
            val.values.forEach(item => {
              arr.push(item.split(','));
            });
            editData.push({
              property: item.property,
              operator: val.operator,
              queryValue: arr,
              type: item.search,
              title: item.title,
            });
          }
          if (val.field === 'department_detail' && item.property === 'standard_dept_name') {
            // const value = val.values.reduce(function (a, b) {
            //   return a.concat(b);
            // });
            const arr = [];
            val.values.forEach(item => {
              arr.push(item.split(','));
            });
            editData.push({
              property: item.property,
              operator: val.operator,
              queryValue: arr,
              type: item.search,
              title: item.title,
            });
          }
          if (item.property === val.field && item.search === 'date') {
            let ope = '';
            // 时间大于等于,还原默认值
            if (val.operator === 10) {
              ope = 9;
            }
            // 时间小于等于
            if (val.operator === 12) {
              ope = 11;
            }
            editData.push({
              property: item.property,
              operator: ope ? ope : val.operator,
              queryValue: val.values.join('\n'),
              type: item.search,
              title: item.title,
            });
          }
        });
      });
      this.ruleData = editData;
      console.log(this.ruleData);
      // 获取试运算覆盖值
      const type = this.tagDetail.type === 'hco' ? 't_hco' : 't_hcp';
      this.showTryCal = true;
      this.getNumberCoverage(type);
    },

    // 标签值-查看规则
    handleShowRule(row) {
      console.log(row, '标签值-查看规则');
      this.ruleDrawer = true;
    },

    // 运行记录-终止运行
    handleStopRun(row) {
      console.log(row, '运行记录-终止运行');
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      if (this.activeTab === 'tag_val') {
        this.requestTagData();
      } else {
        this.getTagRunRecord();
      }
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      if (this.activeTab === 'tag_val') {
        this.requestTagData();
      } else {
        this.getTagRunRecord();
      }
    },
    overlayDataCurrentChange(val) {
      console.log(val);
      this.overlayCurPage = val;
      this.requestOverData();
    },
    overlayDataSizeChange(val) {
      console.log(val);
      this.overlayPageSize = val;
      this.requestOverData();
    },

    getOptions() {
      const optionsApi = [];
      const opts = {};
      this.titleOpts.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.value,
            dict_type: this.tagDetail.type === 'hco' ? '0' : '1',
          };
          if (item.value === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }

          if (item.value === 'type_name') return;
          optionsApi.push(
            apis.GetDictOptionList(params).then(res => {
              opts[item.value] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        Object.keys(opts).forEach(key => {
          this.$set(this.queryValOpts, key, opts[key]);
        });
      });
      // console.log(this.queryValOpts, '下拉框opt');
    },

    // 字段特定排序
    formatFieldOrder() {
      const arr = [...yiyuanConfig, ...dealerConfig, ...shopConfig];
      const arr1 = uniqueArr(arr, 'property');
      const arr2 = hcoCustom.map(ele => ele.key);
      return arr1.sort((x, y) => {
        return arr2.indexOf(x.property) - arr2.indexOf(y.property);
      });
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.overlayConfigLabel.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
